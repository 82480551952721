<template>
  <b-overlay :show="loading">
    <ag-grid-vue
      :gridOptions="gridOptions"
      class="ag-theme-balham ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      @grid-ready="onGridReady"
      :rowData="items"
      rowSelection="single"
      colResizeDefault="shift"
      :animateRows="false"
      :floatingFilter="false"
      :pagination="false"
      :rowClassRules="rowClassRules"
      @selection-changed="onSelectionChanged"
      @first-data-rendered="onFirstDataRendered"
      :pinnedBottomRowData="pinnedBottomRowData"
      :getRowStyle="getRowStyle"
      :alwaysShowVerticalScroll="true"
      :suppressPaginationPanel="true"
    >
    </ag-grid-vue>
  </b-overlay>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BCard, BCardText, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
  },
  props: ["items", "loading"],
  data() {
    return {
      // grid
      gridOptions: {
        suppressPropertyNamesCheck: true,
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        //editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "TIPO DOCUMENTO",
          cellRenderer: function (param) {
            if (!param.node.rowPinned) {
              if (param.data.tipo_documento_id == 1) {
                return "CÉDULA DE IDENTIDAD";
              } else if (param.data.tipo_documento_id == 2) {
                return "CÉDULA DE IDENTIDAD DE EXTRANJERO";
              } else if (param.data.tipoDocumentoID == 3) {
                return "PASAPORTE";
              } else if (param.data.tipo_documento_id == 4) {
                return "OTRO DOCUMENTO DE IDENTIDAD";
              } else {
                return "NÚMERO DE IDENTIFICACIÓN TRIBUTARIA";
              }
            }
          },
          field: "tipo_documento.descripcion",
          filter: false,
          minWidth: 270,
          maxWidth: 270,
        },
        {
          headerName: "NUMERO DOCUMENTO",
          field: "numero_documento",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "RAZON SOCIAL",
          field: "razon_social",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "EMAIL",
          field: "correo_electronico",
          filter: false,
          mminWidth: 400,
          maxWidth: 400,
        },
        {
          headerName: "CELULAR",
          field: "celular",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
        },
        {
          headerName: "SALDO",
          field: "saldo",
          filter: false,
          minWidth: 120,
          maxWidth: 120,
          valueFormatter: numberFormatter,
          type: "numericColumn",
        },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
      searchQuery: "",
    };
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          correo_electronico: "SALDO TOTAL: ",
          saldo: this.calcularSaldo(),
          bold: true,
        },
      ];
    },
  },
  created() {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
  },
  methods: {
    calcularSaldo() {
      let resultado = 0;
      for (let index = 0; index < this.items.length; index++) {
        resultado = resultado + parseFloat(this.items[index].saldo);
      }
      resultado = resultado.toFixed(2);
      return resultado;
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemCliente", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemCliente", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}
</style>
