import axiosIns from "@/libs/axios";

const cuentas_cobrar = async (sede_id) => {
  return await axiosIns.get(`pagos/cuentas-por-cobrar?sede_id=${sede_id}`)
}

const cuentas_pagar = async (sede_id) => {
  return await axiosIns.get(`pagos/cuentas-por-pagar?sede_id=${sede_id}`)
}

const create_cuentas_cobrar = async (data) => {
  return await axiosIns.post('pagos/cuentas-por-cobrar', data)
}

const create_cuentas_pagar = async (data) => {
  return await axiosIns.post('pagos/cuentas-por-pagar', data)
}

const update_cuentas_cobrar = async (id,data) => {
  return await axiosIns.patch(`pagos/cuentas-por-cobrar/${id}` , data)
}

const update_cuentas_pagar = async (id,data) => {
  return await axiosIns.patch(`pagos/cuentas-por-pagar/${id}`, data)
}

const destroy_cuentas_cobrar = async (id) => {
    return await axiosIns.delete(`pagos/cuentas-por-cobrar/${id}`)
}

const destroy_cuentas_pagar = async (id) => {
  return await axiosIns.delete(`pagos/cuentas-por-pagar/${id}`)
}

const cuenta_cobrar_cliente = async (id, sede_id) => {
  return await axiosIns.get(`pagos/cuenta-por-cobrar/cliente/${id}?sede_id=${sede_id}`)
}

const cuenta_pagar_proveedor = async (id, sede_id) => {
return await axiosIns.get(`pagos/cuenta-por-pagar/proveedor/${id}?sede_id=${sede_id}`)
}

const findOnePdf = async (id) => {
  return await axiosIns.get(`pagos/pdf/${id}`)
}
  
export default {
  cuentas_cobrar,
  cuentas_pagar,
  create_cuentas_cobrar,
  create_cuentas_pagar,
  update_cuentas_cobrar,
  update_cuentas_pagar,
  destroy_cuentas_cobrar,
  destroy_cuentas_pagar,
  cuenta_cobrar_cliente,
  cuenta_pagar_proveedor,
  findOnePdf
}
